/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true,
};

//SEO Related settings
const seo = {
  title: "Gennaro Cesaro Portfolio",
  description:
    "Amo il coding e la sensazione di poter creare tutto ciò che mi passa per la testa, di poter progettare e creare cose che abbiano uno scopo e risolvano problemi reali.",
  og: {
    title: "Gennaro Cesaro Portfolio",
    type: "website",
    url: "https://www.gennarocesaro.com/",
  },
};

//Home Page
const greeting = {
  title: "Gennaro Cesaro",
  logo_name: "GennaroCesaro",
  subTitle:
    "Amo il coding e la sensazione di poter creare tutto ciò che mi passa per la testa, di poter progettare e creare cose che abbiano uno scopo e risolvano problemi reali.",
  resumeLink:
    "https://drive.google.com/file/d/18zrv91VcouKFH29H4lw1osFsdfPHRS0N/view?usp=sharing",
  githubProfile: "https://github.com/Moltack",
};

/* Your Social Media Link */
//fontAwesomeIcon: Reference https://fontawesome.com/icons/github?style=brands
//backgroundColor: Reference https://simpleicons.org/
const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/Moltack",
    fontAwesomeIcon: "fa-github",
    backgroundColor: "#181717",
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/gennaro-cesaro-999441135/",
    fontAwesomeIcon: "fa-linkedin-in",
    backgroundColor: "#0077B5",
  },
  {
    name: "Gmail",
    link: "mailto:gen.cesaro@gmail.com",
    fontAwesomeIcon: "fa-google",
    backgroundColor: "#D14836",
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/gennaro.cesaro.3/",
    fontAwesomeIcon: "fa-facebook-f",
    backgroundColor: "#1877F2",
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/gennarocesaro95/",
    fontAwesomeIcon: "fa-instagram",
    backgroundColor: "#E4405F",
  },
];

const skills = {
  title: "Cosa Faccio?",
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end",
        "⚡ Developing mobile applications using Kotlin or Swift",
        "⚡ Creating application backend in Java",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "Angular",
          fontAwesomeClassname: "simple-icons:angular",
          style: {
            color: "#DD1B16",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "Android",
          fontAwesomeClassname: "simple-icons:android",
          style: {
            color: "#3DDC84",
          },
        },
        {
          skillName: "Swift",
          fontAwesomeClassname: "simple-icons:swift",
          style: {
            color: "#DD5F28",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:java",
          style: {
            color: "#f89820",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
      ],
      softwareSkills: [
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
  ],
};

// Education Page
const degrees = {
  degrees: [
    {
      title: "Corso java",
      subtitle: "",
      logo_path: "",
      alt_name: "",
      duration: "2014 - 2015",
      descriptions: [
        "⚡ Nell'anno 2014/2015 ho frequentato un corso java per iniziare ad addentrarmi nel mondo della programmazione con successivo rilascio del certificato di partecipazione",
      ],
    },
    {
      title: "Istituto Tecnico Della Porta - Porzio",
      subtitle: "",
      logo_path: "",
      alt_name: "",
      duration: "2009 - 2014",
      descriptions: [
        "⚡ Diploma di maturità conseguito presso l'istituto Tecnico Della Porta - Porzio con votazione finale di 75/100",
      ],
    },
  ],
};

const certifications = {
  certifications: [
    /*
    {
      title: "Machine Learning",
      subtitle: "- Andrew Ng",
      logo_path: "stanford_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/22MTSSC5WDTM",
      alt_name: "Stanford University",
      color_code: "#8C151599",
    },
    */
  ],
};

// Experience Page
const experience = {
  title: "Esperienze",
  subtitle: "Scegli il lavoro che ami e non lavorerai mai, neanche per un giorno in tutta la tua vita.",
  description: "(Confucio)",
  header_image_path: "experience.svg",
  experiences: [
    {
      title: "Full Stack Developer",
      company: "RELATECH S.P.A",
      company_url: "https://relatech.com/",
      logo_path: "relatech.png",
      duration: "Settembre 2020 - PRESENTE",
      location: "Centro direzionale E5, Napoli",
      description:
        "Consulente presso Walgreens Boots Alliance. Attività di sviluppo, testing e risoluzione anomalie su applicativi legati a Walgreens",
      color: "#0879bf",
    },
    {
      title: "Full Stack Developer",
      company: "M.I. MEDICAL S.R.L",
      company_url: "http://www.mimedical.it/mi/?q=it/azienda/azienda-0",
      logo_path: "mimedical.png",
      duration: "Marzo 2018 - Settembre 2020",
      location: "Marcianise, Caserta",
      description:
        "Progettazione di soluzioni informatiche per la gestione del flusso dei dati legati ai pazienti e per la gestione dell'archiviazione, trasmissione, visualizzazione e stampa delle immagini diagnostiche digitali.",
      color: "#9b1578",
    },
    {
      title: "Full Stack Developer",
      company: "BC SOFT S.R.L.",
      company_url: "https://www.bcsoft.net/",
      logo_path: "bcsoft.png",
      duration: "Gennaio 2016 - Febbraio 2018",
      location: "Centro direzionale F10, Napoli",
      description:
        "Analisi e sviluppo di applicativi web di varie commesse, sviluppo di applicazioni mobile per la gestione di servizi legati al mondo della ristorazione, servizio di docenza con corsi di formazione.",
      color: "#fc1f20",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contattami",
    profile_image_path: "gennaro.png",
    description:
      "Sono disponibile su quasi tutti i social media. Puoi inviarmi un messaggio, ti risponderò entro 24 ore. ",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "Spazio web per condividere le mie passioni ed i miei interessi sperando di suscitare nel lettore entusiasmo e curiosità. Potete trovare dalle ultime cose che sto studiando a foto di cibo",
    link: "https://blog.gennarocesaro.com/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/MpMqtRZytFchMkZ76",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  contactPageData,
};
